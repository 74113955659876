import(/* webpackMode: "eager" */ "/opt/buildhome/repo/packages/next/src/components/emoji.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/packages/next/src/features/content-security-policy/content-security-policy.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/packages/next/src/features/dashboard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/packages/next/src/features/locale-layout/locale-layout.view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/packages/next/src/modules/quisi/link.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/packages/next/src/modules/quisi/note-section.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/packages/next/src/modules/quisi/note.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/packages/next/src/modules/quisi/paragraph.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/packages/next/src/modules/quisi/section.tsx");
